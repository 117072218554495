export interface IState {
	translations: any
	policyUrl: string
	endpoints: IEndpoints
	subscribed: boolean
	message: IMessage
}

export interface IEndpoints {
	subscribeURL: string
}

export interface ISubscribeData {
	successfulCreation: boolean
	message: string
}

export interface ISubscribeUser {
	email: string
	name: string
}

export interface IMessage {
	success: string
	alreadyExists: string
	invalidName: string
	invalidEmail: string
	invalidConsent: string
	error: string
}

const defaultState: IState = {
	translations: {},
	policyUrl: '',
	subscribed: false,
	message: {
		success: '',
		alreadyExists: '',
		invalidName: '',
		invalidEmail: '',
		invalidConsent: '',
		error: ''
	},
	endpoints: {
		subscribeURL: ''
	}
}

export default defaultState;
