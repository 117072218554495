import { IState, ISubscribeUser } from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';
import * as api from '../api';

type PayloadType = 'initApp' | 'setState' | 'subscribe'
export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;


// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})

export const subscribe: ActionCreator<ISubscribeUser> = payload => ({
	type: 'subscribe',
	payload: payload
})

// Action handlers
const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'setState',
		payload
	})
}

const subscribeAction: ActionHandler<ISubscribeUser> = ({ state, commit }, { payload }) => {
	api.subscribe(state.endpoints.subscribeURL, {
		email: payload.email,
		name: payload.name
	}).then((response) => {
		console.log(response)
		commit({
			type: 'subscribe',
			payload: response
		})
	})
	
}

const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	subscribe: subscribeAction
}

export default actions;
